import type { Instance, SnapshotOut } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

/**
 * Credit Card model.
 */
export const CreditCardModel = types.model('CreditCard', {
  cardType: types.string,
  expirationDate: types.string,
  expirationMonth: types.string,
  expirationYear: types.string,
  id: types.string,
  imageUrl: types.maybeNull(types.string),
  isDefault: types.boolean,
  label: types.string,
  lastFour: types.string,
  token: types.string,
});

type CreditCardType = Instance<typeof CreditCardModel>;
export interface CreditCard extends CreditCardType {}
type CreditCardSnapshotType = SnapshotOut<typeof CreditCardModel>;
export interface CreditCardSnapshot extends CreditCardSnapshotType {}
export const createOrderDefaultModel = () => types.maybe(CreditCardModel);
